import React from 'react';
import { Flex, Text } from '@100mslive/roomkit-react';
import InterviewSummaryText from './InterviewSummaryText';
const InterviewSummary = ({ urls, status, role, handleRegenerateAiSummary, clickCount, fromMain }) => {
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        '@md': { fontSize: '12px' },
                    }}
                >
                    Interview Summary
                </Text>
                {role === 'admin' && (
                    <button
                        className={`common-btn ai_btn ${clickCount >= 2 && 'disabled'}`}
                        onClick={handleRegenerateAiSummary}
                        style={{ marginLeft: 'auto' }}
                    >
                        Regenerate AI summary
                    </button>
                )}
            </div>
            {status ? (
                urls.map((url, index) => <InterviewSummaryText key={index} url={url} fromMain={fromMain} />)
            ) : (
                <Flex css={{ size: '100%' }} direction="column">
                    <Flex direction="column" css={{ height: '94vh' }}>
                        <Flex
                            justify="center"
                            direction="column"
                            align="center"
                            className="recordingDiv"
                            css={{ flex: '1 1 0', position: 'relative' }}
                        >
                            <Text
                                variant="body1"
                                css={{
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '40px',
                                    lineHeight: '55px',
                                    color: '#9396A0',
                                    textAlign: 'center',
                                    '@md': { fontSize: '28px' },
                                }}
                            >
                                No Interview Summary Found
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default InterviewSummary;
