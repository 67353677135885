import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Tooltip } from '@100mslive/roomkit-react';
import InterviewSummary from './Pages/FeedbackComponent/InterviewSummary';
import Transcript from './Pages/FeedbackComponent/Transcript';
import TranscriptModal from './TranscriptModal/TranscriptModal';
import transcriptFail from '../assets/images/transcript_fail.svg';
import transcriptLoader from '../assets/images/transcript_loader.gif';
import { fetchTranscript, getInterviewerInterviewDetails } from '../redux/action';

const TranscriptPage = ({
    feedbackForm,
    interviewId,
    rolePage,
    handleRegenerateAiSummary,
    clickCount,
    isSideOpen,
    fromMain,
    roomId,
}) => {
    const screenWidth = window.innerWidth;
    const dispatch = useDispatch();
    const fetchLoading = useSelector((state) => state.Interview.fetchLoading);
    const fetchLoadingFail = useSelector((state) => state.Interview.fetchLoadingFail);
    const [fetchBtnClicked, setFetchBtnClicked] = useState(false);
    const [fetchTranscriptFail, setFetchTranscriptFail] = useState(fetchLoadingFail);
    const handleFetchTranscript = () => {
        setFetchBtnClicked(true);
        dispatch(
            fetchTranscript({
                roomId: roomId,
                callback: () => {
                    dispatch(getInterviewerInterviewDetails({ interviewId: interviewId }));
                },
            })
        );
    };
    return fromMain && !feedbackForm?.transcript?.length ? (
        <div className={isSideOpen ? 'w-70' : 'w-98'}>
            <div className="no-transcript-parent">
                <div className="no-transcript-div">
                    <Text
                        variant="body1"
                        css={{
                            fontStyle: 'normal',
                            fontWeight: '300',
                            fontSize: '12px',
                            lineHeight: '18px',
                            letterSpacing: '5%',
                            color: '#FFFFFF',
                            textAlign: 'left',
                            fontFamily: 'Poppins !important',
                            '@md': { fontSize: '12px' },
                        }}
                    >
                        Transcription and Summary will be available after 30 minutes of interview completion.
                    </Text>
                    <Tooltip
                        disabled={!fetchBtnClicked}
                        title={fetchBtnClicked ? 'Transcript can be generated once per session' : ''}
                    >
                        <button
                            className={`generate-transcript-btn ${fetchBtnClicked && 'disable-transcript-btn'}`}
                            disabled={fetchBtnClicked}
                            onClick={handleFetchTranscript}
                        >
                            Generate Transcript
                        </button>
                    </Tooltip>
                </div>
                {fetchLoading && (
                    <TranscriptModal
                        img={transcriptLoader}
                        text={`We are generating transcript. \n Please wait a moment!`}
                    />
                )}
                {fetchTranscriptFail && (
                    <TranscriptModal
                        img={transcriptFail}
                        imgClass="transcriptFail_img"
                        text={`We are unable to generate transcript. \n Please contact technical team for further assistance `}
                        btnText="Ok"
                        btnClick={() => setFetchTranscriptFail(!fetchTranscriptFail)}
                    />
                )}
            </div>
        </div>
    ) : (
        <div
            style={{
                width: screenWidth > 992 ? (isSideOpen ? '70%' : '98%') : '100%',
                height: screenWidth > 992 ? fromMain : 'calc(100% - 56px)' ? 'calc(100vh-70px)' : '100%',
            }}
            className={`${fromMain ? 'tandScontainerMain tandScontainerMainStyle' : 'tandScontainer'}`}
        >
            <div className={`${!fromMain ? 'tandScontentContainer' : ''}`}>
                <Transcript
                    urls={feedbackForm?.transcript}
                    status={feedbackForm?.transcript.length > 0}
                    fromMain={fromMain}
                />
            </div>
            <div className={`${!fromMain ? 'tandScontentContainer' : ''}`}>
                <InterviewSummary
                    urls={feedbackForm?.interviewSummary}
                    status={feedbackForm?.interviewSummary?.length > 0}
                    interviewId={interviewId}
                    role={rolePage}
                    handleRegenerateAiSummary={handleRegenerateAiSummary}
                    clickCount={clickCount}
                    fromMain={fromMain}
                />
            </div>
        </div>
    );
};

export default TranscriptPage;
