import React, { useEffect, useState } from 'react';
import { Text } from '@100mslive/roomkit-react';
import { isMobileDevice } from '../../../common/utils';

const InterviewSummaryText = ({ url, fromMain }) => {
    const [textData, setTextData] = useState(null);

    useEffect(() => {
        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                try {
                    const jsonData = JSON.parse(data);
                    setTextData(jsonData);
                } catch (parseError) {
                    console.error(`Error parsing JSON data from ${url}:`, parseError);
                }
            })
            .catch((error) => {
                console.error(`Error fetching data from ${url}:`, error);
            });
    }, [url]);

    // Render textData.sections as a list
    const sections =
        textData &&
        textData?.sections
            ?.filter((section) => section?.paragraph !== '')
            .map((section, index) => (
                <code
                    key={index}
                    dangerouslySetInnerHTML={{
                        __html: `${section?.paragraph?.replace(/\*\*/g, '')}`,
                    }}
                />
            ));

    const getStyles = () => {
        if (isMobileDevice())
            return {
                backgroundColor: '#191924',
                height: `${fromMain ? 'calc(100vh - 170px)' : 'calc(100vh - 120px)'}`,
            };
        return {
            backgroundColor: '#191924',
            padding: '10px',
            marginTop: '10px',
            overflowY: 'auto',
            height: `${fromMain ? 'calc(100vh - 170px)' : 'calc(100vh - 120px)'}`,
        };
    };

    return (
        <div style={getStyles()}>
            <Text
                variant="body1"
                css={{
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '5%',
                    color: '#FFFFFF',
                    textAlign: 'left',
                    fontFamily: 'Poppins !important',
                    '@md': { fontSize: '10px' },
                }}
            >
                <pre style={{ whiteSpace: 'pre-wrap' }}>{sections}</pre>
            </Text>
        </div>
    );
};

export default InterviewSummaryText;
