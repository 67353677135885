import { PRIVACY_POLICY, TERMS_AND_CONDITION } from './constant';

export const Toast = ({ msg }) => <>{msg}</>;
export const capitalizeAll = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
export const checkFileValidation = (files) => {
    let message =
        'The uploaded file is not a valid file type. Only .doc, .docx, .pdf, .csv, .xls and .xlsx file types are allowed.';
    const fileTypes = [
        'text/csv', // CSV
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
        'application/pdf', // PDF
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
        'application/msword', // DOC
        'application/vnd.ms-excel', //XLS
    ];
    if (files && fileTypes.includes(files?.type)) {
        return {
            status: true,
            msg: '',
        };
    } else {
        return {
            status: false,
            msg: message,
        };
    }
};
export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
};
export const generateUrl = (urlMode) => {
    const envMode = process.env.REACT_APP_ENV;
    let frontendUrlPreFix, backendUrlPreFix, socketUrlPrefix, externalUrl;
    let finalUrl;
    if (envMode === 'prod') {
        frontendUrlPreFix = '';
        backendUrlPreFix = 'api.';
        socketUrlPrefix = '';
        externalUrl = 'https://prod-in2.100ms.live/hmsapi/incruiter-prod.app.100ms.live/';
    } else if (envMode === 'test' || envMode === 'preprod') {
        frontendUrlPreFix = 'test.v1.';
        backendUrlPreFix = 'test.api.';
        socketUrlPrefix = 'test-';
        externalUrl = 'https://prod-in2.100ms.live/hmsapi/incruiter-preprod-bot-room.app.100ms.live/';
    } else {
        frontendUrlPreFix = 'dev.v1.';
        backendUrlPreFix = 'dev.api.';
        socketUrlPrefix = 'dev-';
        externalUrl = 'https://prod-in2.100ms.live/hmsapi/incruiter-dev-meet.app.100ms.live/';
    }
    if (urlMode === 'token_generation') {
        finalUrl = externalUrl;
    } else if (urlMode === 'face_expression') {
        finalUrl = 'https://fds.incruiter.com:5000';
    } else if (urlMode === 'proctoring') {
        finalUrl = 'https://sfds.incruiter.com:8000';
    } else if (urlMode === 'socket') {
        finalUrl = `https://${socketUrlPrefix}video-feedback-system.incruiter.com`;
    } else if (urlMode === 'interview_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/interview-system/api/v1/incbot`;
    } else if (urlMode === 'pusher_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1/video-platform/pusher`;
    } else if (urlMode === 'video_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1/video-platform`;
    } else if (urlMode === 'meet_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1/meet`;
    } else if (urlMode === 'skills_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/skills-system/api/v1/list/skills`;
    } else if (urlMode === 'skill_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/skills-system/api/v1/skills`;
    } else if (urlMode === 'meeting') {
        finalUrl = `https://${frontendUrlPreFix}video.incruiter.com`;
    } else if (urlMode === PRIVACY_POLICY) {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com/privacy-policy`;
    } else if (urlMode === TERMS_AND_CONDITION) {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com/terms-and-condition`;
    } else if (urlMode === 'file-saver-system') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/file-saver-system/api/v1/fileSave`;
    } else if (urlMode === 'account') {
        finalUrl = `https://${frontendUrlPreFix}account.incruiter.com`;
    }
    return finalUrl;
};
