import React from 'react';
import { toast } from 'react-toastify';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ToastManager } from '../../components/Toast/ToastManager';
import {
    addScreenshotFailure,
    addScreenshotSuccess,
    candidateCallEndFailure,
    candidateCallEndSuccess,
    contactAdminFailure,
    contactAdminSuccess,
    createAndUpdateScreenshotsFailure,
    createAndUpdateScreenshotsSuccess,
    createCodeSubmissionFailure,
    createCodeSubmissionSuccess,
    deleteRecordingFailure,
    deleteRecordingSuccess,
    fetchScreenShotImageFailure,
    fetchScreenShotImageType1Success,
    fetchScreenShotImageType2Success,
    fetchScreenShotImageType3Success,
    fetchScreenShotImageType4Success,
    fetchScreenShotImageType5Success,
    fetchScreenShotImagev2Failure,
    fetchScreenShotImagev2Success,
    fetchScreenShotImagev3Failure,
    fetchScreenShotImagev3Success,
    fetchTranscriptFailure,
    fetchTranscriptSuccess,
    getAllScreenshotsFailure,
    getAllScreenshotsSuccess,
    getCandidateInterviewDetailsFailure,
    getCandidateInterviewDetailsSuccess,
    getCodeEditorLanguagesFailure,
    getCodeEditorLanguagesSuccess,
    getCodeSubmissionFailure,
    getCodeSubmissionSuccess,
    getCropUrlFailure,
    getCropUrlSuccess,
    getFeedbackFailure,
    getFeedbackSuccess,
    getInterviewDetailsFailure,
    getInterviewDetailsSuccess,
    getInterviewerInterviewDetailsFailure,
    getInterviewerInterviewDetailsSuccess,
    getInterviewTokenFailure,
    getInterviewTokenSuccess,
    getQuestionBankFailure,
    getQuestionBankListFailure,
    getQuestionBankListSuccess,
    getQuestionBankSuccess,
    getRecordingsFailure,
    getRecordingsSuccess,
    getRegeneratedAiSummaryFailure,
    getRegeneratedAiSummarySuccess,
    getScreenshotsFailure,
    getScreenshotsSuccess,
    getSingleCodeEditorLanguagesFailure,
    getSingleCodeEditorLanguagesSuccess,
    getSkillsFailure,
    getSkillsSuccess,
    getUrlGeneratedIdFailure,
    getUrlGeneratedIdSuccess,
    interviewerCallEndFailure,
    interviewerCallEndSuccess,
    movementFailure,
    movementSuccess,
    notifyInterviewerFailure,
    notifyInterviewerSuccess,
    postCarFeedbackDetailsFailure,
    postCarFeedbackDetailsSuccess,
    postFeedbackDetailsFailure,
    postFeedbackDetailsSuccess,
    postInterviewFeedbackFailure,
    postInterviewFeedbackSuccess,
    postNoShowFeedbackDetailsFailure,
    postNoShowFeedbackDetailsSuccess,
    roomUpdateFailure,
    roomUpdateSuccess,
    startMeetingFailure,
    startMeetingSuccess,
    switchTabFailure,
    switchTabSuccess,
    verifyInterviewKeyFailure,
    verifyInterviewKeySuccess,
    videoUploadFailure,
    videoUploadSuccess,
} from '../action';
import {
    ADD_SCREENSHOT,
    CANDIDATE_CALL_END,
    CODE_EDITOR_LANGUAGE,
    CONTACT_ADMIN,
    CREATE_AND_UPDATE_SCREENSHOT,
    CREATE_CODE_SUBMISSION,
    DELETE_RECORDING,
    FETCH_SCREENSHOT_IMAGE,
    FETCH_SCREENSHOT_IMAGEV2,
    FETCH_SCREENSHOT_IMAGEV3,
    FETCH_TRANSCRIPT,
    GET_ALL_SCREENSHOT,
    GET_CANDIDATE_INTERVIEW_DETAILS,
    GET_CODE_SUBMISSION,
    GET_CROP_IMAGE,
    GET_FEEDBACK,
    GET_INTERVIEW_DETAILS,
    GET_INTERVIEW_TOKEN,
    GET_INTERVIEWER_INTERVIEW_DETAILS,
    GET_RECORDING_LIST,
    GET_REGENERATED_AI_SUMMARY,
    GET_SCREENSHOT,
    GET_SKILLS,
    GET_URL_GENERATED_ID,
    INTERVIEWER_CALL_END,
    MOVEMENT,
    NOTIFY_INTERVIEWER,
    POST_CAR_FEEDBACK_DETAILS,
    POST_FEEDBACK_DETAILS,
    POST_INTERVIEW_FEEDBACK,
    POST_NOSHOW_FEEDBACK_DETAILS,
    QUESTION_BANK,
    QUESTION_BANK_LIST,
    ROOM_UPDATE,
    SINGLE_CODE_EDITOR_LANGUAGE,
    START_MEETING,
    SWITCH_TAB,
    VERIFY_INTERVIEW_KEY,
    VIDEO_UPLOAD,
} from '../action/types';
import FILE_SAVER from '../../utils/apiUrl/fileSaver';
import SKILL from '../../utils/apiUrl/skill';
import SKILLS from '../../utils/apiUrl/skills';
import VIDEO from '../../utils/apiUrl/video';
import { Toast } from '../../utils/helpers';

function* getQuestionBankRequest(action) {
    try {
        const res = yield SKILL.post('questionBank', action.payload.questionData);
        if (res.status === 200 || res.status === 201) {
            yield put(getQuestionBankSuccess(res.data.result?.questions));
            yield call(action.payload.callback, res?.data?.result?.questions);
        }
    } catch (e) {
        yield put(getQuestionBankFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getScreenshotsRequest(action) {
    try {
        let res = yield VIDEO.get(`getScreenshots/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getScreenshotsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getScreenshotsFailure());
    }
}

function* movementRequest(action) {
    try {
        let res = yield VIDEO.patch('movement', action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(movementSuccess(res.data.result));
        }
    } catch (e) {
        yield put(movementFailure());
    }
}
function* switchTabRequest(action) {
    try {
        let res = yield VIDEO.patch('switchTab', action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(switchTabSuccess(res.data.result));
        }
    } catch (e) {
        yield put(switchTabFailure());
    }
}

function* getAllScreenshotsRequest(action) {
    try {
        let res = yield VIDEO.get(`getScreenshot/${action.payload.interviewId}/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getAllScreenshotsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getAllScreenshotsFailure());
    }
}

function* getCropUrlRequest(action) {
    try {
        let res = yield VIDEO.post(`captureScreenShots`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(getCropUrlSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getCropUrlFailure());
    }
}

function* createAndUpdateScreenshotsRequest(action) {
    try {
        let res = yield VIDEO.post(`createAndUpdateScreenshots/${action.payload.roomId}`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(createAndUpdateScreenshotsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(createAndUpdateScreenshotsFailure());
    }
}

function* addScreenshotsRequest(action) {
    try {
        let res = yield VIDEO.post(`/uploadScreenshots`, action.payload, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 200 || res.status === 201) {
            yield put(addScreenshotSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(addScreenshotFailure());
    }
}

function* getRecordingsRequest(action) {
    try {
        let res;
        if (action.payload.interviewId) {
            res = yield VIDEO.get(`getRecording/${action.payload.interviewId}/${action.payload.roomId}`);
        } else {
            res = yield VIDEO.get(`recordings/${action.payload.roomId}`);
        }
        if (res.status === 200) {
            yield put(getRecordingsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getRecordingsFailure());
    }
}

function* candidateCallEndRequest(action) {
    try {
        const res = yield VIDEO.patch(`callEnd/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(candidateCallEndSuccess(res.data.result));
        }
    } catch (e) {
        yield put(candidateCallEndFailure());
    }
}

function* interviewerCallEndRequest(action) {
    try {
        const res = yield VIDEO.patch(`/interviewer/callEnd/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(interviewerCallEndSuccess(res.data.result));
        }
    } catch (e) {
        yield put(interviewerCallEndFailure());
    }
}

function* getFeedbackRequest(action) {
    try {
        let res;
        if (action.payload.interviewId) {
            res = yield VIDEO.get(`getInterview/${action.payload.interviewId}/${action.payload.roomId}`);
        } else {
            res = yield VIDEO.get(`feedback/${action.payload.roomId}`);
        }
        if (res.status === 200) {
            yield put(getFeedbackSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getFeedbackFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getQuestionBankListRequest() {
    try {
        const res = yield SKILLS.get(`questionBank/language`);
        if (res.status === 200) {
            yield put(getQuestionBankListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getQuestionBankListFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getInterviewTokenRequest(action) {
    try {
        const res = yield VIDEO.get(`getInterviewToken/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getInterviewTokenSuccess(res.data.result));
            localStorage.setItem('token', res.data.result?.token);
        }
    } catch (e) {
        yield put(getInterviewTokenFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getSkillsRequest(action) {
    try {
        const res = yield SKILLS.get('');
        if (res.status === 200) {
            yield put(getSkillsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getSkillsFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getInterviewDetailsRequest(action) {
    try {
        const res = yield VIDEO.get(`getInterviewByIdForPanelist/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(getInterviewDetailsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewDetailsFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getCandidateInterviewDetailsRequest(action) {
    try {
        const res = yield VIDEO.get(`getInterviewByIdForCandidate/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(getCandidateInterviewDetailsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getCandidateInterviewDetailsFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getInterviewerInterviewDetailsRequest(action) {
    try {
        const res = yield VIDEO.get(`getInterviewByIdForPanelist/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(getInterviewerInterviewDetailsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewerInterviewDetailsFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getCodeEditorLanguagesRequest(action) {
    try {
        const res = yield VIDEO.get('codeCompiler/languages');
        if (res.status === 200) {
            yield put(getCodeEditorLanguagesSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getCodeEditorLanguagesFailure());
        yield e?.response?.data?.message;
    }
}

function* getCodeSubmissionRequest(action) {
    try {
        const res = yield VIDEO.get(`codeCompiler/code/${action.payload.token}?base64_encoded=true&fields=*`);
        if (res.status === 200) {
            yield put(getCodeSubmissionSuccess(res.data));
        }
    } catch (e) {
        yield put(getCodeSubmissionFailure());
        yield e?.response?.data?.message;
    }
}

function* getSingleCodeEditorLanguagesRequest(action) {
    try {
        const res = yield VIDEO.get(`codeCompiler/languages/${action.payload.id}`);
        if (res.status === 200) {
            yield put(getSingleCodeEditorLanguagesSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getSingleCodeEditorLanguagesFailure());
        yield e?.response?.data?.message;
    }
}

function* notifyInterviewerRequest(action) {
    try {
        const res = yield VIDEO.post(`sendMailToInterviewer/${action.payload.roomId}`, {});
        if (res.status === 200) {
            yield put(notifyInterviewerSuccess());
        }
    } catch (e) {
        yield put(notifyInterviewerFailure());
        yield e?.response?.data?.message;
    }
}

function* createCodeSubmissionRequest(action) {
    try {
        const res = yield VIDEO.post('codeCompiler/submit?base64_encoded=true&fields=*&wait=true', action.payload.data);
        if (res.status === 201) {
            yield put(createCodeSubmissionSuccess(res.data));
            yield call(action.payload.callback, res?.data?.result);
        }
    } catch (e) {
        yield put(createCodeSubmissionFailure());
        ToastManager.addToast({
            title: '400 Bad Request',
        });
    }
}

function* verifyInterviewKeyRequest(action) {
    try {
        const res = yield VIDEO.post('verifyInterviewKey', action.payload);
        if (res.status === 201) {
            yield put(verifyInterviewKeySuccess(res.data.result));
        }
    } catch (e) {
        yield put(verifyInterviewKeyFailure());
        yield e?.response?.data?.message;
    }
}

function* fetchScreenShotImageRequest(action) {
    try {
        const res = yield VIDEO.post('dataURIUpload', action.payload);
        if (res.status === 201) {
            if (action.payload.type === 1) {
                yield put(fetchScreenShotImageType1Success(res.data.result));
            } else if (action.payload.type === 2) {
                yield put(fetchScreenShotImageType2Success(res.data.result));
            } else if (action.payload.type === 3) {
                yield put(fetchScreenShotImageType3Success(res.data.result));
            } else if (action.payload.type === 4) {
                yield put(fetchScreenShotImageType4Success(res.data.result));
            } else if (action.payload.type === 10) {
                yield call(action.payload.callback, res?.data?.result?.url);
            } else {
                yield put(fetchScreenShotImageType5Success(res.data.result));
            }
        }
    } catch (e) {
        yield put(fetchScreenShotImageFailure());
    }
}

function* fetchScreenShotImagev2Request(action) {
    try {
        const res = yield VIDEO.post('uploadImage', action.payload, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201) {
            yield call(action.payload.callback, res?.data?.result?.url);
            ToastManager.addToast({
                title: 'File has been uploaded successfully.',
            });
            yield put(fetchScreenShotImagev2Success(res.data.result));
        }
    } catch (e) {
        console.log(e);
        ToastManager.addToast({
            title: 'File not supported.',
        });
        yield put(fetchScreenShotImagev2Failure());
    }
}
function* fetchScreenShotImagev3Request(action) {
    try {
        const res = yield VIDEO.post('uploadImage', action.payload, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201) {
            ToastManager.addToast({
                title: 'File has been uploaded successfully.',
            });
            yield put(fetchScreenShotImagev3Success(res.data.result));
        }
    } catch (e) {
        ToastManager.addToast({
            title: 'File not supported.',
        });
        yield put(fetchScreenShotImagev3Failure());
    }
}
function* postInterviewFeedbackRequest(action) {
    try {
        const res = yield VIDEO.post(`interview-feedback/${action.payload.data.interviewId}`, action.payload.data);
        if (res.status === 201) {
            yield put(postInterviewFeedbackSuccess(res.data));
        }
    } catch (e) {
        yield put(postInterviewFeedbackFailure());
        yield e?.response?.data?.message;
    }
}

function* contactAdminRequest(action) {
    try {
        const res = yield VIDEO.post(`contactAdmin`, action.payload);
        if (res.status === 201) {
            yield put(contactAdminSuccess(res.data));
        }
    } catch (e) {
        yield put(contactAdminFailure());
    }
}

function* postNoShowFeedbackDetailsRequest(action) {
    try {
        let res = yield VIDEO.post(`noshow/${action.payload.interviewId}`, {});
        if (res.status === 201) {
            yield put(postNoShowFeedbackDetailsSuccess(res.data));
            ToastManager.addToast({
                title: 'Feedback Submitted successfully.',
            });
        }
    } catch (e) {
        yield put(postNoShowFeedbackDetailsFailure());
        yield e?.response?.data?.message;
    }
}

function* postFeedbackDetailsRequest(action) {
    try {
        let res;
        if (action.payload.type === 1) {
            res = yield VIDEO.post(`addFeedback/${action.payload.interviewId}`, action.payload.data);
        } else {
            res = yield VIDEO.post(`no-show-interview/${action.payload.interviewId}`, {});
        }
        if (res.status === 201) {
            yield put(postFeedbackDetailsSuccess(res.data));
            if (action.payload.data?.type !== 'autosave') {
                ToastManager.addToast({
                    title: 'Feedback Submitted successfully.',
                });
            }
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postFeedbackDetailsFailure());
        yield e?.response?.data?.message;
    }
}

function* startMeetingRequest(action) {
    try {
        let res = yield VIDEO.post(`getMeetingInfo`, action.payload);
        if (res.status === 201 || res.status === 201) {
            yield put(startMeetingSuccess(res.data.result));
        }
    } catch (e) {
        yield put(startMeetingFailure());
        yield e?.response?.data?.message;
    }
}
function* deleteRecordingRequest(action) {
    try {
        let res = yield VIDEO.put('updateRecording', action.payload.updatedData);
        if (res.status === 200 || res.status === 201) {
            yield put(deleteRecordingSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(deleteRecordingFailure());
    }
}
function* getUrlGeneratedIdRequest(action) {
    try {
        const res = yield VIDEO.get(`urlGenerate/${action.payload.interviewId}/${action.payload.urlGenerateId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getUrlGeneratedIdSuccess(res.data.result));
            yield call(action.payload.callback(res));
        }
    } catch (e) {
        yield put(getUrlGeneratedIdFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* getRegeneratedAiSummaryRequest(action) {
    try {
        const res = yield VIDEO.post(`regenerateSummary/${action.payload.interviewId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getRegeneratedAiSummarySuccess(res.data.result));
            yield call(action.payload.callback(res));
        }
    } catch (e) {
        yield put(getRegeneratedAiSummaryFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* fetchedTranscriptRequest(action) {
    try {
        let res = yield VIDEO.post(`fetchTranscript?room_id=${action.payload.roomId}`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(fetchTranscriptSuccess(res.data.result));
            yield res.data?.result?.message &&
                ToastManager.addToast({
                    title: res.data?.result?.message,
                });
            yield call(action.payload.callback);
        } else {
            yield put(fetchTranscriptFailure());
        }
    } catch (e) {
        yield put(fetchTranscriptFailure());
    }
}
function* videoUploadRequest(action) {
    try {
        let res = yield FILE_SAVER.post('video', action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(videoUploadSuccess());
        } else {
            yield put(videoUploadFailure());
            document.getElementById('video-upload').value = null;
            yield toast.error(<Toast msg="That was an error in uploading the file" />);
        }
    } catch (e) {
        yield put(videoUploadFailure());
        document.getElementById('video-upload').value = null;
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* roomUpdateRequest(action) {
    try {
        yield VIDEO.post('roomUpdate', action.payload);
        yield put(roomUpdateSuccess());
    } catch (e) {
        yield put(roomUpdateFailure());
    }
}

function* postCarFeedbackDetailsRequest(action) {
    try {
        const formData = new FormData();
        formData.append('interviewId', action.payload?.data?.interviewId);
        formData.append('status', action.payload?.data?.status);
        formData.append('finalRating', action.payload?.data?.finalRating);
        formData.append('code', JSON.stringify(action.payload?.data?.code));
        formData.append('type', action.payload.data?.type);
        if (action.payload?.data?.carFeedback) formData.append('carFeedback', action.payload.data.carFeedback);

        const res = yield VIDEO.post(`addCarFeedback/${action.payload?.data?.interviewId}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201) {
            yield put(postCarFeedbackDetailsSuccess(res.data));
            if (action.payload.data?.type !== 'auto') {
                ToastManager.addToast({
                    title: 'Feedback Submitted successfully.',
                });
            }
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postCarFeedbackDetailsFailure());
        yield e?.response?.data?.message;
    }
}

// Saga watcher function to trigger startMeetingRequest on GET_SIGNATURE action
export function* watchRoomUpdateAPI() {
    yield takeEvery(ROOM_UPDATE, roomUpdateRequest);
}
export function* watchGetSignature() {
    yield takeEvery(START_MEETING, startMeetingRequest);
}

export function* watchGetQuestionBankAPI() {
    yield takeEvery(QUESTION_BANK, getQuestionBankRequest);
}
export function* watchGetQuestionBankListAPI() {
    yield takeEvery(QUESTION_BANK_LIST, getQuestionBankListRequest);
}
export function* watchFetchTranscriptAPI() {
    yield takeEvery(FETCH_TRANSCRIPT, fetchedTranscriptRequest);
}
export function* watchGetSkillsAPI() {
    yield takeEvery(GET_SKILLS, getSkillsRequest);
}
export function* watchGetRecordingsAPI() {
    yield takeEvery(GET_RECORDING_LIST, getRecordingsRequest);
}
export function* watchGetScreenshotsAPI() {
    yield takeEvery(GET_SCREENSHOT, getScreenshotsRequest);
}
export function* watchGetAllScreenshotsAPI() {
    yield takeEvery(GET_ALL_SCREENSHOT, getAllScreenshotsRequest);
}
export function* watchCreateAndUpdateScreenshotsAPI() {
    yield takeEvery(CREATE_AND_UPDATE_SCREENSHOT, createAndUpdateScreenshotsRequest);
}
export function* watchGetCropUrlRequestAPI() {
    yield takeEvery(GET_CROP_IMAGE, getCropUrlRequest);
}
export function* watchCandidateCallEndAPI() {
    yield takeEvery(CANDIDATE_CALL_END, candidateCallEndRequest);
}
export function* watchInterviewerCallEndAPI() {
    yield takeEvery(INTERVIEWER_CALL_END, interviewerCallEndRequest);
}
export function* watchGetFeedbackAPI() {
    yield takeEvery(GET_FEEDBACK, getFeedbackRequest);
}
export function* watchGetInterviewDetailsAPI() {
    yield takeEvery(GET_INTERVIEW_DETAILS, getInterviewDetailsRequest);
}
export function* watchGetCandidateInterviewDetailsAPI() {
    yield takeEvery(GET_CANDIDATE_INTERVIEW_DETAILS, getCandidateInterviewDetailsRequest);
}
export function* watchGetInterviewerInterviewDetailsAPI() {
    yield takeEvery(GET_INTERVIEWER_INTERVIEW_DETAILS, getInterviewerInterviewDetailsRequest);
}
export function* watchGetInterviewTokenAPI() {
    yield takeEvery(GET_INTERVIEW_TOKEN, getInterviewTokenRequest);
}
export function* watchAddScreenshotAPI() {
    yield takeEvery(ADD_SCREENSHOT, addScreenshotsRequest);
}
export function* watchGetCodeEditorLanguagesAPI() {
    yield takeEvery(CODE_EDITOR_LANGUAGE, getCodeEditorLanguagesRequest);
}
export function* watchGetSingleCodeEditorLanguagesAPI() {
    yield takeEvery(SINGLE_CODE_EDITOR_LANGUAGE, getSingleCodeEditorLanguagesRequest);
}
export function* watchCreateCodeSubmissionAPI() {
    yield takeEvery(CREATE_CODE_SUBMISSION, createCodeSubmissionRequest);
}
export function* watchDeleteRecordingAPI() {
    yield takeEvery(DELETE_RECORDING, deleteRecordingRequest);
}
export function* watchVerifyInterviewKeyAPI() {
    yield takeEvery(VERIFY_INTERVIEW_KEY, verifyInterviewKeyRequest);
}
export function* watchFetchScreenShotImageAPI() {
    yield takeEvery(FETCH_SCREENSHOT_IMAGE, fetchScreenShotImageRequest);
}
export function* watchFetchScreenShotImagev2API() {
    yield takeEvery(FETCH_SCREENSHOT_IMAGEV2, fetchScreenShotImagev2Request);
}
export function* watchFetchScreenShotImagev3API() {
    yield takeEvery(FETCH_SCREENSHOT_IMAGEV3, fetchScreenShotImagev3Request);
}
export function* watchGetCodeSubmissionAPI() {
    yield takeEvery(GET_CODE_SUBMISSION, getCodeSubmissionRequest);
}
export function* watchPostInterviewFeedbackAPI() {
    yield takeEvery(POST_INTERVIEW_FEEDBACK, postInterviewFeedbackRequest);
}
export function* watchPostNoShowFeedbackDetailsAPI() {
    yield takeEvery(POST_NOSHOW_FEEDBACK_DETAILS, postNoShowFeedbackDetailsRequest);
}
export function* watchContactAdminAPI() {
    yield takeEvery(CONTACT_ADMIN, contactAdminRequest);
}
export function* watchNotifyInterviewerAPI() {
    yield takeEvery(NOTIFY_INTERVIEWER, notifyInterviewerRequest);
}
export function* watchPostFeedbackDetailsAPI() {
    yield takeEvery(POST_FEEDBACK_DETAILS, postFeedbackDetailsRequest);
}
export function* watchMovementAPI() {
    yield takeEvery(MOVEMENT, movementRequest);
}
export function* watchSwitchTabAPI() {
    yield takeEvery(SWITCH_TAB, switchTabRequest);
}
export function* watchGetGeneratedIdAPI() {
    yield takeEvery(GET_URL_GENERATED_ID, getUrlGeneratedIdRequest);
}
export function* watchGetRegenerateAiSummaryAPI() {
    yield takeEvery(GET_REGENERATED_AI_SUMMARY, getRegeneratedAiSummaryRequest);
}
export function* watchVideoUploadAPI() {
    yield takeEvery(VIDEO_UPLOAD, videoUploadRequest);
}
export function* watchPostCarFeedbackDetailsAPI() {
    yield takeEvery(POST_CAR_FEEDBACK_DETAILS, postCarFeedbackDetailsRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetQuestionBankAPI(),
        watchGetQuestionBankListAPI(),
        watchGetSkillsAPI(),
        watchGetFeedbackAPI(),
        watchGetInterviewDetailsAPI(),
        watchGetCandidateInterviewDetailsAPI(),
        watchGetInterviewerInterviewDetailsAPI(),
        watchGetInterviewTokenAPI(),
        watchGetCodeEditorLanguagesAPI(),
        watchGetSingleCodeEditorLanguagesAPI(),
        watchVerifyInterviewKeyAPI(),
        watchFetchScreenShotImageAPI(),
        watchFetchScreenShotImagev2API(),
        watchFetchScreenShotImagev3API(),
        watchCreateCodeSubmissionAPI(),
        watchGetCodeSubmissionAPI(),
        watchPostInterviewFeedbackAPI(),
        watchPostFeedbackDetailsAPI(),
        watchContactAdminAPI(),
        watchCandidateCallEndAPI(),
        watchGetRecordingsAPI(),
        watchGetScreenshotsAPI(),
        watchGetAllScreenshotsAPI(),
        watchCreateAndUpdateScreenshotsAPI(),
        watchGetCropUrlRequestAPI(),
        watchPostNoShowFeedbackDetailsAPI(),
        watchGetSignature(),
        watchNotifyInterviewerAPI(),
        watchMovementAPI(),
        watchSwitchTabAPI(),
        watchDeleteRecordingAPI(),
        watchInterviewerCallEndAPI(),
        watchAddScreenshotAPI(),
        watchGetGeneratedIdAPI(),
        watchGetRegenerateAiSummaryAPI(),
        watchFetchTranscriptAPI(),
        watchVideoUploadAPI(),
        watchRoomUpdateAPI(),
        watchPostCarFeedbackDetailsAPI(),
    ]);
}
